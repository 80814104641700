import {
  makeAutoObservable,
  runInAction,
  reaction,
  observable,
  computed,
} from "mobx";

import {
  getAvg,
  getMax,
  getMin,
  sortJsonArr,
  convertToMonth,
  convertToQuater,
} from "../Utilities";
import DataAudit from "../assets/DataAudit/dataAudit.json";
import { axios } from "axios";
import { setLoading } from "./loadingFunction";
import moment, { months } from "moment";
import _ from "lodash";
const cache_days = 7;

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export class Zigna_aiStore {
  //Common variables start
  userType = localStorage.getItem("userType") || null;
  //Common variables end
  service;
  constructor(service) {
    makeAutoObservable(this);
    this.service = service;
  }
  //common variables start
  clientName = "sutherland"; // to see diff logo please change this string into zignaai

  //common variables endd
  // CompetitiveLandscape start
  categoryList = [];
  stateMapDataList = [];
  regionalMapDataList = [];
  payerList = [];
  selectedState = { id: "NY", properties: { name: "New York" } };
  allPayers = [];
  cptDrgList = [];
  selectedFilterData;
  selectedCommonFilterData = {
    payers: [],
  };
  notification = null;
  userSavedFilters = [];
  professionalFilterMaster = null;

  setNotification = (type, message) => {
    this.notification = { type, message };
  };
  // CompetitiveLandscape End

  // PayerspriceComparison start

  // PayerspriceComparison End

  clearCompetitiveLandscape = () => {
    this.categoryList = [];
    this.stateMapDataList = [];
    this.regionalMapDataList = [];
    this.payerList = [];
  };
  getCompetitiveLandscape = () => {
    this.clearCompetitiveLandscape();
    this.getCategoryList();
    this.getStateMapDataList();
    this.getPayerList();
    this.getRegionalMapDataList();
  };
  getUserType = () => {
    const mapping = {
      professional: "professional",
      facility: "facility",
      ancillary: "ancillary",
      dataAuditReport: "dataAuditReport",
    };
    //ancillary
    return mapping[this.userType];
  };
  getListOfPayers = async (isDrg) => {
    const defaultPayer = await this.getLSData(`default_payer`);
    this.selectedCommonFilterData.payers = [
      { id: defaultPayer, name: defaultPayer, value: defaultPayer },
    ];

    if (!this.userType) return;
    const cached = await this.getLSData(`dropdownPayerList_${this.userType}`);
    if (isDrg) {
      let response = await this.service.getTopPrefEntityList({
        userType: this.getUserType(),
      });
      this.allPayers = response?.data;
      return;
    }
    if (!cached) {
      let response = await this.service.getListOfPayers({
        provider_type: this.getUserType(),
      });
      this.allPayers = response?.data;
      if (response.data) {
        await this.setLSData(
          `dropdownPayerList_${this.userType}`,
          response.data
        );
      }
    } else {
      setLoading(true);
      this.allPayers = cached;
    }
    // const selectedPayerList = await this.getLSData(`selectedPayerFilterList_${this.userType}`) || null
    // this.setCommonFilterData('payers', selectedPayerList)
  };
  getCPTDrgEntityList = async (isDrg) => {
    const defaultPayer = await this.getLSData(`default_payer`);

    this.selectedCommonFilterData.payers = [
      { id: defaultPayer, name: defaultPayer, value: defaultPayer },
    ];

    if (!this.userType) return;

    let response = await this.service.getTopPrefEntityList({
      userType: this.getUserType(),
      type: isDrg ? "drg" : "cpt",
    });
    this.allPayers = response?.data;
    return;

    // const selectedPayerList = await this.getLSData(`selectedPayerFilterList_${this.userType}`) || null
    // this.setCommonFilterData('payers', selectedPayerList)
  };

  setCommonFilterData = (key, value) => {
    this.selectedCommonFilterData[key] = value;
  };
  getCategoryList = async () => {
    if (!this.userType) return;
    const cached = await this.getLSData(
      `dashboardCategoryList_${
        this.userType
      }_${this.selectedCommonFilterData.payers.map((i) => i.name).join("-")}`
    );
    const defaultPayer = await this.getLSData(`default_payer`);
    if (!cached) {
      const payerList =
        (this.selectedCommonFilterData.payers || []).map((el) => el.value) ||
        [];
      let response = await this.service.getCategoryList({
        userType: this.getUserType(),
        //payers: (this.selectedCommonFilterData.payers || []).map(el => el.value) || [],
        payers: payerList.length
          ? payerList
          : defaultPayer
          ? [defaultPayer]
          : [],
      });
      this.categoryList = response?.data;
      if (response.data) {
        await this.setLSData(
          `dashboardCategoryList_${
            this.userType
          }_${this.selectedCommonFilterData.payers
            .map((i) => i.name)
            .join("-")}`,
          response.data
        );
      }
    } else {
      setLoading(true);
      this.categoryList = cached;
    }
  };
  getFilterPriceDiffAvgListBaseOnTypeName(typeName) {
    const getData = [];
    this.priceDiffAvgList.data.map((e) => {
      if (typeName) {
        if (e.typeName == typeName) {
          getData.push(e);
        }
      } else {
        if (e.typeName != "percentage" && e.typeName != "per diem") {
          getData.push(e);
        }
      }
    });
    console.log(JSON.parse(JSON.stringify(getData)));
    return getData;
  }
  getStateMapDataList = async () => {
    if (!this.userType) return;
    const cached = await this.getLSData(
      `stateMapList_${this.userType}_${this.selectedCommonFilterData.payers
        .map((i) => i.name)
        .join("-")}`
    );
    if (!cached) {
      let response = await this.service.getStateMapDataList({
        userType: this.getUserType(),
        payers:
          (this.selectedCommonFilterData.payers || []).map((el) => el.value) ||
          [],
      });
      this.stateMapDataList = response?.data;
      if (response.data) {
        await this.setLSData(
          `stateMapList_${this.userType}_${this.selectedCommonFilterData.payers
            .map((i) => i.name)
            .join("-")}`,
          response.data
        );
      }
    } else {
      setLoading(true);
      this.stateMapDataList = cached;
    }
    const defaultStatePointer = await this.getLSData(`defaultStatePointer`);
    if (defaultStatePointer) {
      this.selectedState = {
        id: defaultStatePointer.id,
        properties: { name: defaultStatePointer.value },
      };
    }
  };
  getPayerList = async () => {
    if (!this.userType) return;
    const customTrasform = (data) => {
      const mvpRow = (data || []).find(
        (row) => row.PayerName === "MVP Health Care, Inc."
      );
      if (mvpRow) {
        return (data || []).reduce((acc, row) => {
          if (row.PayerName === "Cigna Health Life Insurance Company") {
            acc.push({
              ...row,
              PayerName: `${row.PayerName}/${mvpRow.PayerName}`,
              Facilities: String(
                parseInt(row.Facilities) + parseInt(mvpRow.Facilities)
              ),
              Physicians: String(
                parseInt(row.Physicians) + parseInt(mvpRow.Physicians)
              ),
              Procedures: String(
                parseInt(row.Procedures) + parseInt(mvpRow.Procedures)
              ),
            });
          } else if (row.PayerName === "MVP Health Care, Inc.") {
            // Do nothing
          } else {
            acc.push(row);
          }
          return acc;
        }, []);
      } else {
        return data;
      }
    };

    const cached = await this.getLSData(`dashboardPayerList_${this.userType}`);
    if (!cached) {
      let response = await this.service.getPayerList({
        userType: this.getUserType(),
      });
      this.payerList = customTrasform(response?.data) || [];
      if (response.data) {
        await this.setLSData(
          `dashboardPayerList_${this.userType}`,
          customTrasform(response.data)
        );
      }
    } else {
      this.payerList = cached;
    }
  };
  getRegionalMapDataList = async () => {
    let self = this;
    if (!this.userType) return;
    const cached = await this.getLSData(
      `regionMapList_${this.userType}_${this.selectedCommonFilterData.payers
        .map((i) => i.name)
        .join("-")}`
    );
    let data = [];
    if (!cached) {
      let response = await this.service.getRegionalMapDataList({
        userType: this.getUserType(),
        payers:
          (this.selectedCommonFilterData.payers || []).map((el) => el.value) ||
          [],
      });
      data = response.data;
      if (response.data) {
        await this.setLSData(
          `regionMapList_${this.userType}_${this.selectedCommonFilterData.payers
            .map((i) => i.name)
            .join("-")}`,
          response.data
        );
      }
    } else {
      setLoading(true);
      data = cached;
    }

    let tempArr = (data || []).filter((item) => {
      return item?.state?.toLowerCase() == self.selectedState?.id.toLowerCase();
    });
    this.regionalMapDataList = [...tempArr];
  };
  setSelectedState = (state) => {
    this.selectedState = {};
    this.selectedState = state;
    this.getPayerList();
    this.getRegionalMapDataList();
  };
  // CompetitiveLandscape

  // Payer Price Comparison start
  // filter
  hospitalList = [];
  ancillaryList = [];
  npiList = [];
  billingCodeTypeList = [];
  proceduresCatList = [];
  level1DescList = [];
  modifierList = [];
  hospitalNameList = []
  aggregationMeasureList = [
    {
      id: 1,
      name: "Minimum",
      value: "Minimum",
    },
    {
      id: 2,
      name: "Median",
      value: "Median",
    },
    {
      id: 3,
      name: "Average",
      value: "Average",
    },
    {
      id: 4,
      name: "Maximum",
      value: "Maximum",
    },
  ];
  procedureNameList = [];

  getHospitalList = async (viewType) => {
    let response = await this.service.getHospitalList({
      userType: this.getUserType(),
      viewType: viewType,
    });
    this.hospitalList = response.data;
  };
  getAncillaryList = async (viewType) => {
    let response = await this.service.getAncillaryList({
      viewType,
    });
    this.ancillaryList = response.data;
  };
  getNpiList = async () => {
    this.npiList = [];
    let body = { userType: this.getUserType(), health_system: this.selectedFilterData.health_system.map((el) => el.value) || [], };
    if (this.userType == "ancillary") {
      body["ancillary"] = this.selectedFilterData.ancillary.value;
    } else {
      body["hospital"] = this.selectedFilterData.hospital.value;
    }
    let response = await this.service.getNpiList(body);
    this.npiList = response.data;
  };
  getHospitalNameList = async () => {
    this.hospitalNameList = [];
    let body = { userType: this.getUserType() };
    if (this.userType == "ancillary") {
      body["ancillary"] = this.selectedFilterData.ancillary.value;
    } else {
      body["hospital"] = this.selectedFilterData.hospital.value;
    }
    let response = await this.service.getHospitalNameList(body);
    this.hospitalNameList = response.data;
  };
  getBillingCodeTypeList = async () => {
    this.billingCodeTypeList = [];
    let body = {
      billingCodeType: this.selectedFilterData.procedureCat,
      npi: this.selectedFilterData.npi.map((el) => el.value) || [],
      health_system: this.selectedFilterData.health_system.map((el) => el.value) || [],
      payersCount: this.selectedFilterData.payersCount,
      userType: this.getUserType(),
    };

    if (this.userType == "ancillary") {
      body["ancillary"] = this.selectedFilterData.ancillary.value;
    } else {
      body["hospital"] = this.selectedFilterData.hospital.value;
    }

    let response = await this.service.getBillingCodeTypeList(body);
    this.billingCodeTypeList = response.data;
  };
  getProceduresCatList = async () => {
    this.proceduresCatList = [];
    let body = {
      hnpi: this.selectedFilterData.npi.map((el) => el.value) || [],
      payersCount: this.selectedFilterData.payersCount,
      userType: this.getUserType(),
      health_system: this.selectedFilterData.health_system.map((el) => el.value) || [],
    };

    if (this.userType == "ancillary") {
      body["ancillary"] = this.selectedFilterData.ancillary.value;
    } else {
      body["hospital"] = this.selectedFilterData.hospital.value;
    }
    let response = await this.service.getProceduresCatList(body);
    this.proceduresCatList = response.data;
  };
  getLevel1DescList = async () => {
    this.level1DescList = [];
    let body = {
      billingCodeType: this.selectedFilterData.procedureCat,
      npi: this.selectedFilterData.npi.map((el) => el.value) || [],
      payersCount: this.selectedFilterData.payersCount,
      rBillingCodeType: this.selectedFilterData.billingCodeType
        ? this.selectedFilterData.billingCodeType.map((el) => el.value)
        : [],
      userType: this.getUserType(),
      health_system: this.selectedFilterData.health_system.map((el) => el.value) || [],
    };

    if (this.userType == "ancillary") {
      body["ancillary"] = this.selectedFilterData.ancillary.value;
    } else {
      body["hospital"] = this.selectedFilterData.hospital.value;
    }
    let response = await this.service.getLevel1DescList(body);
    this.level1DescList = response.data;
  };
  getProcedureNameList = async () => {
    this.procedureNameList = [];
    let body = {
      billingCodeType: this.selectedFilterData.procedureCat,
      npi: this.selectedFilterData.npi.map((el) => el.value) || [],
      description: this.selectedFilterData.level1Desc.value,
      payersCount: this.selectedFilterData.payersCount,
      rBillingCodeType: this.selectedFilterData.billingCodeType
        ? this.selectedFilterData.billingCodeType.map((el) => el.value)
        : [],
      userType: this.getUserType(),
      health_system: this.selectedFilterData.health_system.map((el) => el.value) || [],
    };
    if (this.userType == "ancillary") {
      body["ancillary"] = this.selectedFilterData.ancillary.value;
    } else {
      body["hospital"] = this.selectedFilterData.hospital.value;
    }
    let response = await this.service.getProcedureNameList(body);
    this.procedureNameList = response.data;
  };
  getmodifierList = async () => {
    this.modifierList = [];
    let body = {
      billingCodeType: this.selectedFilterData.procedureCat,
      npi: this.selectedFilterData.npi.map((el) => el.value) || [],
      description: this.selectedFilterData.level1Desc.value,
      procedure: this.selectedFilterData.procedureName.value,
      payersCount: this.selectedFilterData.payersCount,
      userType: this.getUserType(),
      health_system: this.selectedFilterData.health_system.map((el) => el.value) || [],
    };
    if (this.userType == "ancillary") {
      body["ancillary"] = this.selectedFilterData.ancillary.value;
    } else {
      body["hospital"] = this.selectedFilterData.hospital.value;
    }
    let response = await this.service.getmodifierList(body);
    this.modifierList = response.data;
  };
  // filter

  selectedFilterData = {
    hospital: null,
    npi: null,
    procedureCat: null,
    level1Desc: null,
    aggregationMeasure: null,
    procedureName: null,
    modifier: null,
    ancillary: null, // added ancillary
    billingCodeType: null,
    payersCount: 1,
    health_system: null
  };
  combinedPriceDiffList = [];
  priceCategoryList = [];
  priceDiffAvgList = {
    negotiatedTypes: [],
    data: [],
  };
  priceDiffAvgChartList = [
    {
      id: "chart",
      data: [],
    },
  ];
  setFilterData = (key, value) => {
    this.selectedFilterData[key] = value;
  };
  resetFilterData = () => {
    this.selectedFilterData = {
      hospital: null,
      npi: null,
      procedureCat: null,
      level1Desc: null,
      aggregationMeasure: null,
      procedureName: null,
      modifier: null,
      ancillary: null, // added ancillary
      payersCount: 1,
      health_system: null
    };
  };
  changeFilter(filterName, value) {}
  isValidFilter = () => {
    let self = this;
    let isValid = true;
    Object.keys(this.selectedFilterData).map((key) => {
      if (self.selectedFilterData[key] == null) {
        self.selectedFilterData[key] = "";
      }
      if (
        key != "npi" &&
        key != "health_system" &&
        key != "modifier" &&
        key != "procedureName" &&
        key != "payersCount" &&
        key != "billingCodeType" &&
        key != (this.userType == "ancillary" ? "hospital" : "ancillary") &&
        self.selectedFilterData[key] == ""
      ) {
        isValid = false;
      }
    });

    return isValid;
  };
  getPayerPriceCompList = async (codes) => {
    // this.getHospitalList();
    const transformData = (data) => {
      const typeWiseData = {};
      data.forEach((element) => {
        const type = element["typeName"];
        if (
          ["fee schedule", "derived", "negotiated", "Fee Schedule"].includes(
            type
          )
        ) {
          if (!typeWiseData["negotiated"]) {
            typeWiseData["negotiated"] = [];
          }
          typeWiseData["negotiated"].push(element);
        } else {
          if (!typeWiseData[type]) {
            typeWiseData[type] = [];
          }
          typeWiseData[type].push(element);
        }
      });
      return typeWiseData;
    };
    this.combinedPriceDiffList = [];
    // console.log(this.isValidFilter())
    if (this.isValidFilter()) {
      let body = {
        billingCodeType: this.selectedFilterData.procedureCat,
        npi: this.selectedFilterData.npi.map((el) => el.value) || [],
        description: this.selectedFilterData.level1Desc.value,
        procedure: this.selectedFilterData.procedureName.value,
        aggregationMeasure: this.selectedFilterData.aggregationMeasure.value,
        modifier: this.selectedFilterData.modifier.value,
        payersCount: this.selectedFilterData.payersCount,
        userType: this.getUserType(),
        billingCodes: codes,
      health_system: this.selectedFilterData.health_system.map((el) => el.value) || [],
      };
      if (this.userType == "facility") {  
        body["hospital"] = this.selectedFilterData.hospital.value;
      } else {
        body["ancillary"] = this.selectedFilterData.ancillary.value;
      }
      body["rBillingCodeType"] = this.selectedFilterData.billingCodeType
        ? this.selectedFilterData.billingCodeType.map((el) => el.value)
        : [];
      // console.log(body,'body');
      let response = await this.service.getPayerPriceCompList(body);
      this.priceDiffAvgList = response?.data;
      const splittedData = transformData(this.priceDiffAvgList.data);
      let tempData = [];
      for (const [key, value] of Object.entries(splittedData)) {
        tempData.push({
          label: key,
          priceCategoryList: this.setPriceCategoryList(value),
          priceDiffAvgChartList: this.setPriceDiffAvgChartList(value),
        });
      }
      //custom logic to be removed later
      const nego = tempData.find((val) => val.label === "negotiated");
      const perdiem = tempData.find((val) => val.label === "per diem");
      const filtered = tempData.filter(
        (val) => val.label != "negotiated" && val.label != "per diem"
      );
      this.combinedPriceDiffList = [
        ...(nego ? [nego] : []),
        ...(perdiem ? [perdiem] : []),
        ...filtered,
      ];
    }
    // console.log(this.combinedPriceDiffList, "this.combinedPriceDiffList")
  };
  resetPayerPriceCompList = () => {
    this.priceDiffAvgList = {
      negotiatedTypes: [],
      data: [],
    };
    this.combinedPriceDiffList = [];
  };
  setPriceCategoryList(data) {
    let dataList = data;
    let maxPrice = [];
    let minPrice = [];
    let average = 0;
    maxPrice = getMax(dataList, "price");
    // this.priceDiffAvgList?.data?.filter( x => x["price"] == Math.max(...self.priceDiffAvgList.data.map(x => x["price"])) )
    minPrice = getMin(dataList, "price");
    average = getAvg(dataList, "price");
    let result = [];
    result.push(
      {
        id: 3,
        priceCat: "Minimum Price",
        value: minPrice && minPrice[0]?.price,
      },
      {
        id: 1,
        priceCat: "Maximum Price",
        value: maxPrice && maxPrice[0]?.price,
      },
      {
        id: 2,
        priceCat: "Average Price",
        value: average && average?.toFixed(0),
      }
    );
    return result;
  }
  setPriceDiffAvgChartList(data) {
    let dataList = data;
    let average = getAvg(dataList, "price").toFixed(2);

    let result = [
      {
        id: "chart",
        data: [],
      },
    ];
    let resultDataArr = dataList?.map((item) => {
      return {
        x: item.payerName,
        // y: item.price,
        y: (average - item.price)?.toFixed() * -1,
        priceDiff: (average - item.price)?.toFixed(2),
        priceDiffPercentage: (
          ((average - item.price) * 100) /
          parseFloat(average)
        )?.toFixed(),
      };
    });
    console.log(resultDataArr);
    result[0].data = sortJsonArr(resultDataArr, "y", "asc");
    return result;
  }
  downloadPriceCompData = () => {
    this.service.downloadPriceCompData(this.selectedFilterData);
  };
  // Payer Price Comparison End

  //Cost Differential Report Start

  hospitalCostDiffList = [];
  getHopitalCostDiffList = async () => {
    let response = await this.service.getHopitalCostDiffList();
    this.hospitalCostDiffList = [...response.data];
  };
  //Cost Differential Report End

  //Top CPTs/DRGs Overview Start

  topOverviewList = {
    categoryList: [],
    overviewList: [],
    topProcedures: [],
    topProceduresNotPref: [],
    topProceduresL4: [],
    toptopProceduresNotPrefL4: [],
  };

  resetTopOverviewList = () => {
    this.topOverviewList = {
      categoryList: [],
      overviewList: [],
      topProcedures: [],
      topProceduresNotPref: [],
      topProceduresL4: [],
      toptopProceduresNotPrefL4: [],
    };
  };

  getOverviewDRGList = async () => {
    if (!this.userType) return;
    const cached = await this.getLSData(`topDRGs_${this.userType}`);
    this.resetTopOverviewList();
    if (!cached) {
      let response = await this.service.getOverviewDRGList({
        userType: this.getUserType(),
        payers:
          (this.selectedCommonFilterData.payers || []).map((el) => el.value) ||
          [],
      });
      this.topOverviewList = response.data;
      if (response.data) {
        await this.setLSData(`topDRGs_${this.userType}`, response.data);
      }
    } else {
      this.topOverviewList = cached;
    }
  };
  getOverviewCPTList = async () => {
    if (!this.userType) return;
    const cached = await this.getLSData(`topCPTs_${this.userType}`);
    this.topOverviewList = {
      categoryList: [],
      overviewList: [],
      topProcedures: [],
      topProceduresNotPref: [],
      topProceduresL4: [],
      toptopProceduresNotPrefL4: [],
    };
    if (!cached) {
      let response = await this.service.getOverviewCPTList({
        userType: this.getUserType(),
        payers:
          (this.selectedCommonFilterData.payers || []).map((el) => el.value) ||
          [],
      });
      this.topOverviewList = response.data;
      if (response.data) {
        await this.setLSData(`topCPTs_${this.userType}`, response.data);
      }
    } else {
      this.topOverviewList = cached;
    }
  };

  gettopProceduresL4 = async (l4) => {
    if (!this.userType) return;

    // this.topOverviewList = {
    // 	categoryList: [],
    // 	overviewList: [],
    // 	topProcedures: [],
    // 	topProceduresNotPref: [],
    // 	topProceduresL4:[],
    //         toptopProceduresNotPrefL4:[],
    // }

    let response = await this.service.gettopProceduresL4({
      userType: this.getUserType(),
      payers:
        (this.selectedCommonFilterData.payers || []).map((el) => el.value) ||
        [],
      level4_description: l4,
    });
    this.topOverviewList["topProceduresL4"] = response.data;
  };

  gettopProceduresNonPrefL4 = async (l4) => {
    if (!this.userType) return;

    // this.topOverviewList = {
    // 	categoryList: [],
    // 	overviewList: [],
    // 	topProcedures: [],
    // 	topProceduresNotPref: [],
    // 	topProceduresL4:[],
    //         toptopProceduresNotPrefL4:[],
    // }

    let response = await this.service.gettopProceduresNonPrefL4({
      userType: this.getUserType(),
      payers:
        (this.selectedCommonFilterData.payers || []).map((el) => el.value) ||
        [],
      level4_description: l4,
    });
    this.topOverviewList["toptopProceduresNotPrefL4"] = response.data;
  };

  getLSData = async (key) => {
    const user = await localStorage.getItem("currentUser");
    const userObj = JSON.parse(user);
    if (userObj[key]) {
      const data = userObj[key];
      if (
        data["created_on"] &&
        data["data"] &&
        moment(new Date()).diff(moment(data["created_on"]), "days") < cache_days
      ) {
        return data["data"];
      } else {
        return data;
      }
    }
    return undefined;
  };

  setLSData = async (key, data) => {
    const user = await localStorage.getItem("currentUser");
    let userObj = JSON.parse(user);
    userObj = {
      ...userObj,
      [key]: {
        created_on: new Date(),
        data: data,
      },
    };
    await localStorage.setItem("currentUser", JSON.stringify(userObj));
  };
  deleteLSData = async (key) => {
    const user = await localStorage.getItem("currentUser");
    let userObj = JSON.parse(user);
    const { [key]: targetKey, ...rest } = userObj;
    userObj = rest;
    await localStorage.setItem("currentUser", JSON.stringify(userObj));
  };
  //Top CPTs/DRGs Overview End

  /* Filtered DRG screen state start */

  filteredDRGtemp = {
    hospitalList: [],
    ancillaryList: [],
    payerList: [],
    billingCodes: [],
    billingCodeType: [],
    hospitalNameList: []
  };

  filteredDRGSelected = {
    hospital: null,
    ancillary: null,
    payers: null,
    billingCodes: null,
    billingCodeType: null,
    health_system: null
  };

  filteredDRGData = {};
  filteredDRGRawData = [];

  resetFilteredDrgList = () => {
    this.filteredDRGData = {};
    this.filteredDRGRawData = [];
  };

  setfdrgFilterData = (key, value) => {
    this.filteredDRGSelected[key] = value;
  };

  getfdrgHospitalList = async (viewType) => {
    let response = await this.service.getFilteredDRGHospitalList({
      viewType: viewType,
    });
    this.filteredDRGtemp["hospitalList"] = response.data;
  };
  getfdrgAncillaryList = async (viewType) => {
    let response = await this.service.getFilteredDRGAncillaryList({
      viewType: viewType,
    });
    this.filteredDRGtemp["ancillaryList"] = response.data;
  };
  getfdrgPayerList = async () => {
    this.npiList = [];
    let response = await this.service.getFilteredDRGPayerList({
      [this.userType == "ancillary" ? "ancillary" : "hospital"]:
        this.filteredDRGSelected[
          this.userType == "ancillary" ? "ancillary" : "hospital"
        ].map((el) => el.value) || [],
      userType: this.getUserType(),
      health_system: this.filteredDRGSelected.health_system.map((el) => el.value) || [],
    });
    this.filteredDRGtemp["payerList"] = response.data;
  };
  getfdrgHospitalNameList = async () => {
    let response = await this.service.getFilteredDRGHospitalNameList({
      [this.userType == "ancillary" ? "ancillary" : "hospital"]:
        this.filteredDRGSelected[
          this.userType == "ancillary" ? "ancillary" : "hospital"
        ].map((el) => el.value) || [],
      userType: this.getUserType(),
    });
    this.filteredDRGtemp["hospitalNameList"] = response.data;
  };
  getfdrgBillingCodes = async () => {
    this.npiList = [];

    let response = await this.service.getFilteredDRGBillingCodes({
      [this.userType == "ancillary" ? "ancillary" : "hospital"]:
        this.filteredDRGSelected[
          this.userType == "ancillary" ? "ancillary" : "hospital"
        ].map((el) => el.value) || [],
      payers: this.filteredDRGSelected.payers.map((el) => el.value) || [],
      billingCodeType:
        this.filteredDRGSelected.billingCodeType.map((el) => el.value) || [],
      userType: this.getUserType(),
      health_system: this.filteredDRGSelected.health_system.map((el) => el.value) || [],
    });
    this.filteredDRGtemp["billingCodes"] = response.data;
  };

  getfdrgBillingCodeType = async () => {
    this.npiList = [];
    let response = await this.service.getFilteredDRGBillingCodeType({
      [this.userType == "ancillary" ? "ancillary" : "hospital"]:
        this.filteredDRGSelected[
          this.userType == "ancillary" ? "ancillary" : "hospital"
        ].map((el) => el.value) || [],
      payers: this.filteredDRGSelected.payers.map((el) => el.value) || [],
      userType: this.getUserType(),
      health_system: this.filteredDRGSelected.health_system.map((el) => el.value) || [],
    });
    this.filteredDRGtemp["billingCodeType"] = response.data;
  };

  ValidfdrgFilter = () => {
    let self = this;
    let isValid = true;
    Object.keys(this.filteredDRGSelected).map((key) => {
      if (self.filteredDRGSelected[key] == null) {
        self.filteredDRGSelected[key] = "";
      }
      if (
        key != (this.userType == "ancillary" ? "hospital" : "ancillary") && key != "health_system" &&
        self.filteredDRGSelected[key] == ""
      ) {
        isValid = false;
      }
    });
    return isValid;
  };

  getFilteredDrgListRequest = (requestAll, fields = {}) => {
    return this.service.getFilteredDRGListData({
      [this.userType == "ancillary" ? "ancillary" : "hospital"]:
        this.filteredDRGSelected[
          this.userType == "ancillary" ? "ancillary" : "hospital"
        ].map((el) => el.value) || [],
      payers: this.filteredDRGSelected.payers.map((el) => el.value) || [],
      billingCodeType:
        this.filteredDRGSelected.billingCodeType.map((el) => el.value) || [],
      billingCodes:
        this.filteredDRGSelected.billingCodes.map((el) => el.value) || [],
      userType: this.getUserType(),
      requestAll: requestAll,
      health_system: this.filteredDRGSelected.health_system.map((el) => el.value) || [],
      ...fields,
    });
  };

  getFilteredDrgList = async () => {
    const transformData = (arr = []) => {
      let columns = [];
      const rowsObj = arr.reduce((acc, elem) => {
        columns.push(elem.reporting_entity_name);
        if (!acc[elem.billing_code])
          acc[elem.billing_code] = {
            billing_code: elem.billing_code,
            hospital: elem.hospital,
            negotiated_type: elem.negotiated_type,
            description: elem.description,
            reporting_entity_list: { rate: [] },
          };
        acc[elem.billing_code].reporting_entity_list[elem.reporting_entity_name]
          ? acc[elem.billing_code].reporting_entity_list[
              elem.reporting_entity_name
            ].rate.push(elem.rate)
          : (acc[elem.billing_code].reporting_entity_list[
              elem.reporting_entity_name
            ] = { rate: [elem.rate] });
        return acc;
      }, {});
      columns = [...new Set(columns)];
      const rows = [];
      console.log(rowsObj, "rowobj here");
      for (const [key, value] of Object.entries(rowsObj)) {
        rows.push({
          billing_code: key,
          hospital: value.hospital,
          negotiated_type: value.negotiated_type,
          description: value.description,
          ...columns.reduce((acc, elem) => {
            acc[elem] = value.reporting_entity_list[elem]
              ? _.mean(value.reporting_entity_list[elem].rate)
              : 0;
            return acc;
          }, {}),
        });
      }
      return { columns, rows };
    };

    const splitHospitalWiseData = (data = []) => {
      const hospitalWiseData = data.reduce((acc, elem) => {
        if (!acc[elem.hospital]) acc[elem.hospital] = [];
        acc[elem.hospital].push(elem);
        return acc;
      }, {});

      let c = [];
      let r = [];
      for (const [key, value] of Object.entries(hospitalWiseData)) {
        const { columns, rows } = transformData(value);
        c = c.concat(columns);
        r = r.concat(rows);
      }
      return { columns: [...new Set(c)], rows: r };
    };

    this.filteredDRGData = {};
    this.filteredDRGRawData = [];
    // console.log(this.ValidfdrgFilter())
    if (this.ValidfdrgFilter()) {
      let response = await this.getFilteredDrgListRequest(false);
      this.filteredDRGData = splitHospitalWiseData(response?.data);
      this.filteredDRGRawData = response?.data || [];
    } else {
      this.filteredDRGData = {};
      this.filteredDRGRawData = [];
    }
  };

  placeFilteredDrgListRequest = async (fields) => {
    try {
      let response = await this.getFilteredDrgListRequest(true, fields);
      this.setNotification(
        "success",
        "Your request has been placed successfully"
      );
    } catch (e) {
      this.setNotification(
        "error",
        "There was an error creating the request, please try saving it with unique label name using which you can identify the request uniquely"
      );
    }
  };

  drgReferenceTable = [];
  getDrgReferenceTable = async (userType) => {
    if (!this.userType || !userType) return;
    const cached = await this.getLSData(
      `drgReferenceTable_${userType || this.userType}`
    );
    this.drgReferenceTable = [];
    if (!cached) {
      let response = await this.service.getDrgReferenceTable({
        userType: userType || this.getUserType(),
        payers:
          (this.selectedCommonFilterData.payers || []).map((el) => el.value) ||
          [],
      });
      this.drgReferenceTable = response.data;
      if (response.data) {
        await this.setLSData(
          `drgReferenceTable_${userType || this.userType}`,
          response.data
        );
      }
    } else {
      this.drgReferenceTable = cached;
    }
  };
  dataAuditReferenceTable = [];
  getDataAuditReferenceTable = async () => {
    if (!this.userType) return;
    const cached = await this.getLSData(
      `dataAuditReferenceTable${this.userType}`
    );
    this.dataAuditReferenceTable = [];
    if (!cached) {
      let response = JSON.parse(JSON.stringify(DataAudit));

      // let response = await this.service.getDrgReferenceTable({
      // 	userType: this.getUserType(),
      // 	payers: (this.selectedCommonFilterData.payers || []).map(el => el.value) || [],
      // })
      this.dataAuditReferenceTable = response;
      if (response) {
        await this.setLSData(
          `dataAuditReferenceTable${this.userType}`,
          response
        );
      }
    } else {
      this.dataAuditReferenceTable = cached;
    }
  };
  /* Filtered DRG screen state end */
  /* Procedure cost by hospital screen state start */

  procedureCostByHospitaltemp = {
    procedureCode: [],
    procedureCodeType: [],
    procedureCodeGroup: [],
    procedureCodeSubGroup: [],
    negotiatedType: [],
    billing_code_modifier: [],
  };

  procedureCostByHospitalSelected = {
    procedureCode: [],
    procedureCodeType: [],
    procedureCodeGroup: [],
    procedureCodeSubGroup: [],
    reporting_entity: [],
    negotiatedType: null,
    billing_code_modifier: null,
  };

  procedureCostByHospitalData = [];
  procedureCostByHospitalRawData = [];

  getProcedureCostByHospital = async (provider_type) => {
    let response = await this.service.getProcedureCostByHospital({
      reportingEntity:
        this.procedureCostByHospitalSelected.reporting_entity.value,
      city: this.procedureCostByHospitalSelected.city?.value,
      l1description: this.procedureCostByHospitalSelected.l1description?.value,
      provider_type: provider_type,
      negotiated_type:
        this.procedureCostByHospitalSelected.negotiatedType?.value,
      billing_code_type: this.procedureCostByHospitalSelected.codeType.map(
        (i) => i.value
      ),
      billing_code_modifier:
        this.procedureCostByHospitalSelected.billing_code_modifier?.value,
    });

    this.procedureCostByHospitalData = response?.data || [];
  };

  setPCHfilterData = async (key, value) => {
    this.procedureCostByHospitalSelected[key] = value;
  };

  validateProcedureCostByHospital = () => {
    let self = this;
    let isValid = true;
    Object.keys(this.procedureCostByHospitalSelected).map((key) => {
      if (self.procedureCostByHospitalSelected[key] == null) {
        self.procedureCostByHospitalSelected[key] = "";
      }
      if (
        key != "procedureCode" &&
        self.procedureCostByHospitalSelected[key] == ""
      ) {
        isValid = false;
      }
    });
    return isValid;
  };

  getPCHcodeType = async (provider_type) => {
    let response = await this.service.getPCHcodeType({
      provider_type: provider_type,
    });
    this.procedureCostByHospitaltemp["codeType"] = response.data;
  };

  getPCHcity = async (provider_type) => {
    let response = await this.service.getPCHcity({
      reportingEntity:
        this.procedureCostByHospitalSelected.reporting_entity.value,
      l1description: this.procedureCostByHospitalSelected.l1description?.value,
      provider_type: provider_type,
      negotiated_type:
        this.procedureCostByHospitalSelected.negotiatedType?.value,
      billing_code_type: this.procedureCostByHospitalSelected.codeType.map(
        (i) => i.value
      ),
    });
    this.procedureCostByHospitaltemp["city"] = response.data;
  };

  getPCHreportingEntity = async (provider_type) => {
    let response = await this.service.getPCHreportingEntity({
      l1description: this.procedureCostByHospitalSelected.l1description?.value,
      provider_type: provider_type,
      negotiated_type:
        this.procedureCostByHospitalSelected.negotiatedType?.value,
      billing_code_type: this.procedureCostByHospitalSelected.codeType.map(
        (i) => i.value
      ),
    });
    this.procedureCostByHospitaltemp["reporting_entity"] = response.data;
  };
  getPCHl1description = async (provider_type) => {
    let response = await this.service.getPCHl1description({
      provider_type: provider_type,
      negotiated_type:
        this.procedureCostByHospitalSelected.negotiatedType?.value,
      billing_code_type: this.procedureCostByHospitalSelected.codeType.map(
        (i) => i.value
      ),
    });
    this.procedureCostByHospitaltemp["l1description"] = response.data;
  };
  getPCHnegitiatedType = async (provider_type) => {
    let response = await this.service.getPCHnegotiatedType({
      provider_type: provider_type,
      billing_code_type: this.procedureCostByHospitalSelected.codeType.map(
        (i) => i.value
      ),
    });
    this.procedureCostByHospitaltemp["negotiatedType"] = response.data;
  };

  getPCHbillingCodeModifiers = async (provider_type) => {
    let response = await this.service.getPCHbillingCodeModifiers({
      reportingEntity:
        this.procedureCostByHospitalSelected.reporting_entity.value,
      city: this.procedureCostByHospitalSelected.city?.value,
      l1description: this.procedureCostByHospitalSelected.l1description?.value,
      provider_type: provider_type,
      negotiated_type:
        this.procedureCostByHospitalSelected.negotiatedType?.value,
      billing_code_type: this.procedureCostByHospitalSelected.codeType.map(
        (i) => i.value
      ),
    });

    console.log(response.data, "pch");
    this.procedureCostByHospitaltemp["billing_code_modifier"] = response.data;
  };

  resetPCHList = () => {
    this.procedureCostByHospitalData = [];
  };

  setProcedureCostByHospital = async () => {};

  /* Procedure cost by hospital screen state end */

  /* DRG cost breakdown state*/

  drgCostBreakdownFilters = {
    provider_name: [],
    reporting_entity: [],
    allEntities: [],
  };

  resetCostBreakdownFiterList = () => {
    this.drgCostBreakdownFilters = {
      provider_name: [],
      reporting_entity: [],
      allEntities: [],
    };
  };

  drgCostBreakdownSelected = {
    provider_name: null,
    reporting_entity: [],
    comparingEntity: {
      name: JSON.parse(localStorage.getItem("currentUser"))?.payer,
      value: JSON.parse(localStorage.getItem("currentUser"))?.payer,
    },
  };

  drgcostbreakdownData = [];

  resetdrgcostBreakdown = () => {
    this.drgcostbreakdownData = [];
    this.drgCostBreakdownFilters = {
      provider_name: [],
      reporting_entity: [],
      code_category: [],
    };

    this.drgCostBreakdownSelected = {
      provider_name: null,
      reporting_entity: [],
      code_category: null,
      comparingEntity: {
        name: JSON.parse(localStorage.getItem("currentUser"))?.payer,
        value: JSON.parse(localStorage.getItem("currentUser"))?.payer,
      },
    };
  };

  setDrgBreakdownSelected = async (key, value) => {
    this.drgCostBreakdownSelected[key] = value;
  };

  getDDRGcostreportingEntity = async () => {
    let response = await this.service.getDRGcostreportingEntity({
      provider_name: this.drgCostBreakdownSelected.provider_name?.value,
      provider_type: this.getUserType(),
      comparingEntity: this.drgCostBreakdownSelected?.comparingEntity?.value,
      r_billing_code_type: this.drgCostBreakdownSelected?.code_category?.map(
        (i) => i.value
      ),
    });
    this.drgCostBreakdownFilters["reporting_entity"] = response.data;
  };

  getDRGcostallentity = async () => {
    let response = await this.service.getDRGcostallentity({
      provider_type: this.getUserType(),
    });
    this.drgCostBreakdownFilters["allEntities"] = response.data;
  };

  getDRGcostProviderName = async () => {
    let response = await this.service.getDRGcostProviderName({
      provider_type: this.getUserType(),
      comparingEntity: this.drgCostBreakdownSelected?.comparingEntity?.value,
    });
    this.drgCostBreakdownFilters["provider_name"] = response.data;
  };

  getDRGcodeCategory = async () => {
    let response = await this.service.getDRGcodeCategory({
      provider_type: this.getUserType(),
      comparingEntity: this.drgCostBreakdownSelected?.comparingEntity?.value,
      provider_name: this.drgCostBreakdownSelected?.provider_name?.value,
    });
    this.drgCostBreakdownFilters["code_category"] = response.data;
  };

  getDRGcostbreakdown = async () => {
    let response = await this.service.getDRGcostbreakdown({
      provider_name: this.drgCostBreakdownSelected.provider_name?.value,
      reporting_entity_name:
        this.drgCostBreakdownSelected.reporting_entity?.map((i) => i.value),
      provider_type: this.getUserType(),
      r_billing_code_type: this.drgCostBreakdownSelected?.code_category?.map(
        (i) => i.value
      ),
      comparingEntity: this.drgCostBreakdownSelected?.comparingEntity?.value,
    });

    this.drgcostbreakdownData = response.data;
  };

  /* DRG cost breakdown state*/

  HospitalCountByDRG = [];
  HospitalCountByDRGSelected = {
    month: [],
    billing_code_type: [],
    state: [],
    provider_type: [],
  };
  HospitalCountByDRGFilter = {
    reporting_entity: [],
  };

  HospitalCountByDRG2 = [];
  HospitalCountByDRGSelected2 = {
    month: [],
    billing_code_type: [],
    state: [],
    provider_type: [],
  };
  HospitalCountByDRGFilter2 = {
    reporting_entity: [],
  };

  resetAll = () => {
    this.HospitalCountByDRGFilter = { reporting_entity: [] };
    this.HospitalCountByDRGSelected = {
      reporting_entity: null,
      month: [],
      billing_code_type: [],
      state: [],
      provider_type: [],
    };
    this.DRgBYHospital = [];
    this.DrgByHospitalCountWithNtype = [];
  };
  resetAll2 = () => {
    this.HospitalCountByDRGFilter2 = { reporting_entity: [] };
    this.HospitalCountByDRGSelected2 = {
      reporting_entity: null,
      month: [],
      billing_code_type: [],
      state: [],
      provider_type: [],
    };
    this.DrgByHospitalCountWithNtype = [];
  };

  DRgBYHospital = [];
  DistingDRGByNegotiations = [];
  DrgByHospitalCountWithNtype = [];
  setHospitalCountByDRG = async (key, value, v) => {
    if (v == 2) this.HospitalCountByDRGSelected2[key] = value;
    else this.HospitalCountByDRGSelected[key] = value;
  };

  getHospitalCountDrg = async (type) => {
    let response = await this.service.getHospitalCountDrg(type);
    this.HospitalCountByDRG = response.data.map((i) => ({
      ...i,
      month: monthNames[new Date(i.month).getMonth()],
    }));
  };

  getReportingEntityDrgByHospital = async (v) => {
    let response = await this.service.getReportingEntityDrgByHospital();
    if (v == 2)
      this.HospitalCountByDRGFilter2["reporting_entity"] = response.data;
    else this.HospitalCountByDRGFilter["reporting_entity"] = response.data;
  };

  getMonthDrgByHospital = async (v) => {
    let response = await this.service.getMonthDrgByHospital({
      reporting_entity_name:
        v == 2
          ? this.HospitalCountByDRGSelected2.reporting_entity.value
          : this.HospitalCountByDRGSelected.reporting_entity.value,
      data_type: this.HospitalCountByDRGSelected.data_type.value,
    });

    if (v == 2)
      this.HospitalCountByDRGFilter2["month"] = response.data.map((i) => ({
        id: monthNames[new Date(i.name).getMonth()],
        value: i.name,
        name: monthNames[new Date(i.name).getMonth()],
      }));
    else
      this.HospitalCountByDRGFilter["month"] = response.data.map((i) => ({
        id: monthNames[new Date(i.name).getMonth()],
        value: i.name,
        name: monthNames[new Date(i.name).getMonth()],
      }));
  };

  getProviderDrgByHospital = async (reportingEntity, data_type) => {
    let response = await this.service.getProviderDrgByHospital({
      reporting_entity_name: reportingEntity,
      data_type: data_type,
    });

    this.HospitalCountByDRGFilter["provider_type"] = response.data;
  };

  getcodeDrgByHospital = async (v) => {
    let response = await this.service.getcodeDrgByHospital({
      reporting_entity_name:
        v == 2
          ? this.HospitalCountByDRGSelected2.reporting_entity.value
          : this.HospitalCountByDRGSelected.reporting_entity.value,
      months:
        v == 2
          ? this.HospitalCountByDRGSelected2.month.map((i) => i.value)
          : this.HospitalCountByDRGSelected.month.map((i) => i.value),
      provider_type:
        v == 2
          ? this.HospitalCountByDRGSelected2.provider_type.map((i) => i.value)
          : this.HospitalCountByDRGSelected.provider_type.map((i) => i.value),
      data_type: this.HospitalCountByDRGSelected.data_type.value,
    });
    if (v == 2)
      this.HospitalCountByDRGFilter2["billing_code_type"] = response.data;
    else this.HospitalCountByDRGFilter["billing_code_type"] = response.data;
  };

  getstateDrgByHospital = async (reportingEntity, data_type) => {
    let response = await this.service.getstateDrgByHospital({
      reporting_entity_name: reportingEntity,
      data_type: data_type,
      provider_type: this.HospitalCountByDRGSelected.provider_type.value,
    });

    this.HospitalCountByDRGFilter["state"] = response.data;
  };

  getdDrgByHospitalCount = async (reportingEntity, data_type) => {
    let response = await this.service.getdDrgByHospitalCount({
      reporting_entity_name: reportingEntity,
      data_type: data_type,
      provider_type: this.HospitalCountByDRGSelected.provider_type.value,
      state: this.HospitalCountByDRGSelected.state.value,
    });

    this.DRgBYHospital = response.data;
  };

  getdDrgByHospitalCountWithNtype = async (reportingEntity, data_type) => {
    let response = await this.service.getdDrgByHospitalCountWithNtype({
      reporting_entity_name: reportingEntity,
      data_type: data_type,
      provider_type: this.HospitalCountByDRGSelected.provider_type.value,
      state: this.HospitalCountByDRGSelected.state.value,
    });

    this.DrgByHospitalCountWithNtype = response.data;
  };

  getDistingDRGByNegotiations = async (type) => {
    let response = await this.service.getDistingDRGByNegotiations(type);

    this.DistingDRGByNegotiations = response.data;
  };

  npiDistributionMap = [];

  npiDistributionAcrossCountry = async (body) => {
    let response = await this.service.npiDistributionAcrossCountry(body);
    this.npiDistributionMap = response.data;
  };

  staticChartData = [];
  getStaticChartData = async (body) => {
    let response = await this.service.getStaticChartData(body);
    this.staticChartData = response.data;
  };

  staticBCBVData = [];
  getStaticBCBVData = async () => {
    let response = await this.service.getStaticBCBVData();
    this.staticBCBVData = response.data;
  };

  /**
   * Historical requests section
   */

  historicalRequests = [];
  DataAuditDataType = "relevant";

  setDataAuditDataType = (type) => {
    this.DataAuditDataType = type;
  };
  getHistoricalRequests = async () => {
    this.historicalRequests = [];
    try {
      let response = await this.service.getHistoricalRequests({
        userType: this.getUserType(),
      });
      this.historicalRequests = response.data;
    } catch (e) {
      this.historicalRequests = [];
    }
  };

  placeReRequest = async (fields, id, request_type = "other") => {
    console.log({ fields, id, request_type });
    try {
      let response = await this.service.placeReRequest({
        userType: this.getUserType(),
        label: fields?.label,
        id: id,
        request_type,
        db: fields?.db,
      });
      this.setNotification(
        "success",
        "Your request has been placed successfully"
      );
      await this.getHistoricalRequests();
    } catch (e) {
      this.setNotification(
        "error",
        "There was an error creating the request, please try saving it with unique label name using which you can identify the request uniquely"
      );
    }
  };

  providerExtractFilters = {};
  providerExtractResult = [];
  providerExtractChartResult = [];
  getProviderExtractFilters = async (data) => {
    let response = await this.service.getProviderExtractFilters(data);
    this.providerExtractFilters = response.data;
  };

  getProviderExtarctResults = async (data) => {
    let response = await this.service.getProviderExtractResults(data);
    this.providerExtractResult = response.data;
  };
  getProviderExtractResultsCharts = async (data) => {
    let response = await this.service.getProviderExtractResultsCharts(data);
    this.providerExtractChartResult = response.data;
  };
  resetProviderExtractChartResult = () => {
    this.providerExtractChartResult = [];
  };
  placeCategoryExtractRequest = async (fields, notify, billingCodeType) => {
    try {
      let response = await this.service.getProviderExtractResults({
        ...fields,
        billingCodeType,
        requestAll: true,
      });
      notify("success", "Your request has been placed successfully");
    } catch (e) {
      notify(
        "error",
        "There was an error creating the request, please try saving it with unique label name using which you can identify the request uniquely"
      );
    }
  };
  resetproviderExtractResult = () => {
    this.providerExtractResult = [];
  };

  facilityScoreCardnpiData = [];
  facilityScoreCardScore = [];
  getFacilityScoreCardScores = async () => {
    let response = await this.service.getFacilityScoreCardScores();
    this.facilityScoreCardScore = response.data;
  };

  getFacilityScoreCardNPIs = async () => {
    let response = await this.service.getFacilityScoreCardNPIs();

    this.facilityScoreCardnpiData = response.data;
  };

  //professional screens
  rateInsightData = [];
  professionalRegions = [];
  professionalType = [];
  professionalTin = [];
  uniqueNpis = [];
  professionalRateChoiceData = [];
  professionalRateChoiceCount = 0;
  professionalOptionalFilter = {};
  rateInsightsData = {};
  getProfessionalRegions = async () => {
    let response = await this.service.getProfessionalRegions();

    this.professionalRegions = response.data;
  };
  getProfessionalProvider = async () => {
    let response = await this.service.getProfessionalProvider();

    this.professionalType = response.data;
  };

  getProfessionalTin = async (region, provider_type) => {
    let response = await this.service.getProfessionalTin(region, provider_type);

    this.professionalTin = response.data;
  };
  getProfessionalOptionalFilters = async (body) => {
    let response = await this.service.getProfessionalOptionalFilters(body);
    this.professionalOptionalFilter = response.data;
    if (body.filters && body.filters.length === 0) {
      console.log("master save");
      this.professionalFilterMaster = response.data;
    }
  };
  getProfessionalOptionalFilters2 = async (body) => {
    let response = await this.service.getProfessionalOptionalFilters2(body);
    this.professionalOptionalFilter = response.data;
    if (body.filters && body.filters.length === 0) {
      console.log("master save");
      this.professionalFilterMaster = response.data;
    }
  };

  getRateChoiceData = async (body, returnData = false) => {
    let response = await this.service.getRateChoiceData(body);
    if (returnData) return response.data?.data;
    this.professionalRateChoiceData = response.data?.data;
    this.professionalRateChoiceCount = response.data?.count;
  };

  getRateChoiceDataByTin = async (body, returnData = false) => {
    let response = await this.service.getRateChoiceDataByTin(body);
    if (returnData) return response.data?.data;
    this.professionalRateChoiceData = response.data?.data;
    this.professionalRateChoiceCount = response.data?.count;
  };

  getRateUtilizationData = async (body, returnData = false) => {
    let response = await this.service.getRateUtilizationData(body);
    if (returnData) return response.data?.data;
    this.professionalRateChoiceData = response.data?.data;
    this.professionalRateChoiceCount = response.data?.count;
  };

  getRateUtilizationDataByTin = async (body, returnData = false) => {
    let response = await this.service.getRateUtilizationDataByTin(body);
    if (returnData) return response.data?.data;
    this.professionalRateChoiceData = response.data?.data;
    this.professionalRateChoiceCount = response.data?.count;
    return response.data?.data;
  };
  getRateInsightData = async (body, returnData = false) => {
    return new Promise(async (resolve, reject) => {
      let response = await this.service.getRateInsightData(body);
      if (response.data?.data && response.data?.uniqueNpi) {
        this.rateInsightData = response.data?.data;
        this.uniqueNpis = response.data?.uniqueNpi;
      }
      resolve(response.data);
    });
  };

  getRateInsightsByTin = async (body, returnData = false) => {
    let response = await this.service.getRateInsightsByTin(body);
    if (returnData) return response.data;
    this.rateInsightsData = response.data;
    return response.data;
  };

  getDetailsOfAllTin = async (body) => {
    let response = await this.service.getAllNpiDetails(body);
    return response.data;
  };

  placeRateChoiceDataRequest = async (body, notify) => {
    try {
      let response = await this.service.getRateChoiceData({
        ...body,
        requestAll: true,
      });
      notify("success", "Your request has been placed successfully");
    } catch (e) {
      notify(
        "error",
        e?.response?.data?.error ||
          "There was an error creating the request, please try saving it with unique label name using which you can identify the request uniquely"
      );
    }
  };

  placeRateChoiceDataRequestByTin = async (body, notify) => {
    try {
      let response = await this.service.getRateChoiceDataByTin({
        ...body,
        requestAll: true,
      });
      notify("success", "Your request has been placed successfully");
    } catch (e) {
      notify(
        "error",
        e?.response?.data?.error ||
          "There was an error creating the request, please try saving it with unique label name using which you can identify the request uniquely"
      );
    }
  };

  placeRateUtilizationDataRequest = async (body, notify) => {
    try {
      let response = await this.service.getRateUtilizationData({
        ...body,
        requestAll: true,
      });
      notify("success", "Your request has been placed successfully");
    } catch (e) {
      notify(
        "error",
        e?.response?.data?.error ||
          "There was an error creating the request, please try saving it with unique label name using which you can identify the request uniquely"
      );
    }
  };

  placeRateUtilizationDataRequestByTin = async (body, notify) => {
    try {
      let response = await this.service.getRateUtilizationDataByTin({
        ...body,
        requestAll: true,
      });
      notify("success", "Your request has been placed successfully");
    } catch (e) {
      notify(
        "error",
        e?.response?.data?.error ||
          "There was an error creating the request, please try saving it with unique label name using which you can identify the request uniquely"
      );
    }
  };

  placeRateInsightsRequest = async (body, notify) => {
    try {
      let response = await this.service.getRateInsightData({
        ...body,
        requestAll: true,
      });
      notify("success", "Your request has been placed successfully");
    } catch (e) {
      notify(
        "error",
        e?.response?.data?.error ||
          "There was an error creating the request, please try saving it with unique label name using which you can identify the request uniquely"
      );
    }
  };

  getUserSavedFilters = async () => {};

  saveUserFilters = async (filters) => {
    try {
      const result = await this.service.saveUserFilters(filters);
    } catch (error) {
      // notify(
      //   "error",
      //   "Failed to save filters"
      // )
    }
  };

  fetchUserFilters = async () => {
    try {
      const response = await this.service.fetchUserFilters();
      this.userSavedFilters = response.data;
      console.log("Fetched filters", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  getSignedUrl = async (body) => {
    let response = await this.service.getSignedUrl(body);
    if (response) return response.data;
  };
}
