import React, { useEffect } from 'react'
import { Col, Row, Card, Button, Form, Badge } from '@themesberg/react-bootstrap'
import { inject, observer } from 'mobx-react'
import Select from '../../ui/Dropdown'
import { components } from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useRef } from 'react'
import * as XLSX from 'xlsx';
import { useLocation } from 'react-router-dom'



const customStyles = {
	control: (base) => ({
		...base,
		background: '#e7e3f9',
		height: 44,
		minHeight: 44,
		minWidth: 100,
		width: 240,
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: '#c3b9f1',
			}
		},
	}),
	multiValue: (styles, { data }) => {
		return {
			...styles,
			backgroundColor: '#c3b9f1',
		}
	},
}
const getLabelValue = (label)=>{
	let value='';
	switch(label){
		case 'Primary':
			value='MSDRG-APRDRG Crosswalk';
			break;
		case 'Secondary':
				value='Additional 1:1 mapping between APRDRG and MSDRG';
			break;	
		case 'Teritary':
				value=`Many to 1 mapping between APRDRG and MSDRG`;
			break;
		case 'Unmapped':
				value=`Codes that don't have a crosswalk between MSDRG and APRDRG`;
			break;
	}	
	return value;
}
const OptionForCodeType = (props) => {
	return props.data.id == '0' ? (
		<div>
			<components.Option {...props}>
				<input
					type="checkbox"
					checked={props.data.value != 'All' ? true : false}
					onChange={() => null}
				/>{' '}
				<label>{props.label} </label>
				
			</components.Option>
		</div>
	) : (
		<div>
			<components.Option {...props}>
				<input
					type="checkbox"
					checked={props.isSelected}
					onChange={() => null}
				/>{' '}
				<label>{props.label}</label>	
				<div><small >{getLabelValue(props.label)}</small></div>					
			</components.Option>
		</div>
	)
}
export default inject('zigna_aiStore')(
	observer(({zigna_aiStore, hideModifier }) => {
		const npiSelectRef = useRef()
		let location = useLocation();
		useEffect(() => {
			zigna_aiStore.setFilterData((zigna_aiStore.userType == 'facility'?'hospital':'ancillary'), null)
			zigna_aiStore.setfdrgFilterData('health_system', [])
			zigna_aiStore.setfdrgFilterData('payers', null)
			zigna_aiStore.setfdrgFilterData('billingCodes', null)
			zigna_aiStore.setfdrgFilterData('billingCodeType', null)
			
			
		}, [zigna_aiStore.userType, location])

	
	
		return (
			<Card border="light" className="shadow-sm filterSection">
				<Card.Body>
					<h5>
						Filter{' '}
					</h5>
					<Row>
						{/* <Col className=" col-auto col-auto">
							<div
								className="mb-3"
								title={zigna_aiStore.filteredDRGSelected.hospital?.name}
							>
								<label className="form-label">Hospital<span className='text-danger'>*</span></label>
								<Select
									className={`select-menu ${
										zigna_aiStore.filteredDRGSelected.hospital != ''
											? ''
											: 'invalidFilter'
									}`}
									value={zigna_aiStore.filteredDRGSelected.hospital}
									options={zigna_aiStore.filteredDRGtemp.hospitalList}
									onChange={(e) => {
										zigna_aiStore.setfdrgFilterData('hospital', e)
										zigna_aiStore.setfdrgFilterData('payers', null)
										zigna_aiStore.setfdrgFilterData('billingCodes', null)
										zigna_aiStore.getfdrgPayerList()
									}}
									getOptionLabel={(option) => `${option.name}`}
									styles={customStyles}
								/>
							</div>
						</Col> */}
						<Col className=" col-auto col-auto">
						{zigna_aiStore.userType == 'facility'
        ? <div className="mb-3">								
		<label className="form-label">Health System <span className='text-danger'>*</span></label>
		<div className="d-flex align-items-center h45 NPI-select">
			<Select
				className={`select-menu`}
				value={zigna_aiStore.filteredDRGSelected.hospital}
				onChange={(e, data) => {
					
						zigna_aiStore.setfdrgFilterData('hospital', e)
					
					zigna_aiStore.resetFilteredDrgList()
					zigna_aiStore.resetFilteredDrgList()
					zigna_aiStore.setfdrgFilterData('health_system', [])
					zigna_aiStore.setfdrgFilterData('payers', null)
					zigna_aiStore.setfdrgFilterData('billingCodes', null)
					zigna_aiStore.setfdrgFilterData('billingCodeType', null)
				
				}}
				isMulti
				getOptionLabel={(option) => `${option.name}`}
				styles={customStyles}
				options={
					zigna_aiStore.filteredDRGtemp.hospitalList?.length
						? zigna_aiStore.filteredDRGtemp.hospitalList 
						: []
				}
				allowSelectAll={true}
			
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
				
			/>
			<button
				type="button"
				disabled={
					zigna_aiStore.filteredDRGSelected.hospital == '' ||
					zigna_aiStore.filteredDRGSelected.hospital == undefined ||
					zigna_aiStore.filteredDRGSelected.hospital == null ||
					!zigna_aiStore.filteredDRGSelected.hospital?.length
				}
				className="NPI-submit btn btn-primary btn-sm"
				onClick={()=>{
					zigna_aiStore.resetFilteredDrgList()
					zigna_aiStore.setfdrgFilterData('health_system', [])
					zigna_aiStore.setfdrgFilterData('payers', null)
					zigna_aiStore.setfdrgFilterData('billingCodes', null)
					zigna_aiStore.setfdrgFilterData('billingCodeType', null)
				
					zigna_aiStore.getfdrgPayerList()
					zigna_aiStore.getfdrgHospitalNameList()
				}}
			>
				<FontAwesomeIcon
					icon={faArrowRight}
					className="dropdown-arrow"
					size="lg"
				/>
			</button>
		</div>
		<label style={{
			marginTop: 5,
			fontSize: 12,
			maxWidth: 275,
			fontStyle: 'italic'
		}}>Please select less than 20 hospitals at a time for better performance.</label>
	</div>
        : <div className="mb-3">								
		<label className="form-label">Health System <span className='text-danger'>*</span></label>
		<div className="d-flex align-items-center h45 NPI-select">
			<Select
				className={`select-menu`}
				value={zigna_aiStore.filteredDRGSelected.ancillary}
				onChange={(e, data) => {
					
						zigna_aiStore.setfdrgFilterData('ancillary', e)
					
					zigna_aiStore.resetFilteredDrgList()
				    zigna_aiStore.setfdrgFilterData('health_system', [])
					zigna_aiStore.setfdrgFilterData('payers', null)
					zigna_aiStore.setfdrgFilterData('billingCodes', null)
					zigna_aiStore.setfdrgFilterData('billingCodeType', null)
				}}
				isMulti
				getOptionLabel={(option) => `${option.name}`}
				styles={customStyles}
				options={
					zigna_aiStore.filteredDRGtemp.ancillaryList?.length
						? zigna_aiStore.filteredDRGtemp.ancillaryList
						: []
				}
				allowSelectAll={true}
				
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
		
			/>
			<button
				type="button"
				disabled={
					zigna_aiStore.filteredDRGSelected.ancillary == '' ||
					zigna_aiStore.filteredDRGSelected.ancillary == undefined ||
					zigna_aiStore.filteredDRGSelected.ancillary == null ||
					!zigna_aiStore.filteredDRGSelected.ancillary?.length
				}
				className="NPI-submit btn btn-primary btn-sm"
				onClick={()=>{
					zigna_aiStore.resetFilteredDrgList()
					zigna_aiStore.setfdrgFilterData('payers', null)
					zigna_aiStore.setfdrgFilterData('health_system', [])
					zigna_aiStore.setfdrgFilterData('billingCodes', null)
					zigna_aiStore.setfdrgFilterData('billingCodeType', null)
					zigna_aiStore.getfdrgPayerList()
					zigna_aiStore.getfdrgHospitalNameList()
					
				}}
			>
				<FontAwesomeIcon
					icon={faArrowRight}
					className="dropdown-arrow"
					size="lg"
				/>
			</button>
		</div>
		<label style={{
			marginTop: 5,
			fontSize: 12,
			maxWidth: 275,
			fontStyle: 'italic'
		}}>Please select less than 20 hospitals at a time for better performance.</label>
	</div>
      }
							
						</Col>
						<Col className=" col-auto col-auto">
							<div className="mb-3">
								<label className="form-label">Hospital Name</label>
								<div className="d-flex align-items-center h45 NPI-select">
									<Select
										className={`select-menu`}
										isMulti
										value={zigna_aiStore.filteredDRGSelected.health_system}
										options={
											zigna_aiStore.filteredDRGtemp.hospitalNameList?.length
												? zigna_aiStore.filteredDRGtemp.hospitalNameList
												: []
										}
										onChange={(e, data) => {
					
											zigna_aiStore.setfdrgFilterData('health_system', e)
										
										zigna_aiStore.resetFilteredDrgList()
										zigna_aiStore.setfdrgFilterData('payers', null)
										zigna_aiStore.setfdrgFilterData('billingCodes', null)
										zigna_aiStore.setfdrgFilterData('billingCodeType', null)
									}}
										getOptionLabel={(option) => `${option.name}`}
										styles={customStyles}
										allowSelectAll={true}
										
										closeMenuOnSelect={false}
										hideSelectedOptions={false}
									/>
									<button
										type="button"
										disabled={
											zigna_aiStore.filteredDRGSelected.health_system == '' ||
											zigna_aiStore.filteredDRGSelected.health_system == undefined ||
											zigna_aiStore.filteredDRGSelected.health_system == null ||
											!zigna_aiStore.filteredDRGSelected.health_system?.length
										}
										className="NPI-submit btn btn-primary btn-sm"
										onClick={()=>{
											zigna_aiStore.resetFilteredDrgList()
											zigna_aiStore.setfdrgFilterData('payers', null)
											zigna_aiStore.setfdrgFilterData('billingCodes', null)
											zigna_aiStore.setfdrgFilterData('billingCodeType', null)
											zigna_aiStore.getfdrgPayerList()
											
										}}
									>
										<FontAwesomeIcon
											icon={faArrowRight}
											className="dropdown-arrow"
											size="lg"
										/>
									</button>
									</div>
									</div>
									</Col>
						<Col className=" col-auto col-auto">
							<div className="mb-3">
								<label className="form-label">Payers <span className='text-danger'>*</span></label>
								<div className="d-flex align-items-center h45 NPI-select">
									<Select
										className={`select-menu`}
										value={zigna_aiStore.filteredDRGSelected.payers}
										onChange={(e, data) => {
											
												zigna_aiStore.setfdrgFilterData('payers', e)
												zigna_aiStore.setfdrgFilterData('billingCodes', null)
												zigna_aiStore.setfdrgFilterData('billingCodeType', null)
											zigna_aiStore.resetFilteredDrgList()
										}}
										isMulti
										getOptionLabel={(option) => `${option.name}`}
										styles={customStyles}
										options={
											zigna_aiStore.filteredDRGtemp.payerList?.length
												?zigna_aiStore.filteredDRGtemp.payerList 
												: []
										}
										allowSelectAll={true}
										
										closeMenuOnSelect={false}
										hideSelectedOptions={false}
									
									/>
									<button
										type="button"
										disabled={
											zigna_aiStore.filteredDRGSelected.payers == '' ||
											zigna_aiStore.filteredDRGSelected.payers == undefined ||
											zigna_aiStore.filteredDRGSelected.payers == null ||
											!zigna_aiStore.filteredDRGSelected.payers?.length
										}
										className="NPI-submit btn btn-primary btn-sm"
										onClick={()=>{
											zigna_aiStore.resetFilteredDrgList()
											zigna_aiStore.setfdrgFilterData('billingCodes', null)
											zigna_aiStore.setfdrgFilterData('billingCodeType', null)
											
											zigna_aiStore.getfdrgBillingCodeType()
										}}
									>
										<FontAwesomeIcon
											icon={faArrowRight}
											className="dropdown-arrow"
											size="lg"
										/>
									</button>
								</div>
							</div>
						</Col>
						
						<Col className=" col-auto col-auto">
							<div className="mb-3">
							<label className="form-label">Code Type <span className='text-danger'>*</span></label>
								<div className="d-flex align-items-center h45 NPI-select">
									<Select
										className={`select-menu`}
										value={zigna_aiStore.filteredDRGSelected.billingCodeType}
										onChange={(e, data) => {
											
												zigna_aiStore.setfdrgFilterData('billingCodeType', e)
												zigna_aiStore.setfdrgFilterData('billingCodes', null)		
											zigna_aiStore.resetFilteredDrgList()
										}}
										isMulti
										getOptionLabel={(option) => `${option.name}`}
										styles={customStyles}
										options={
											zigna_aiStore.filteredDRGtemp.billingCodeType?.length
												? zigna_aiStore.filteredDRGtemp.billingCodeType
												: []
										}
										allowSelectAll={true}
										components={{
											Option:OptionForCodeType,
										}}
										closeMenuOnSelect={false}
										hideSelectedOptions={false}
										ref={npiSelectRef}
									/>
									<button
										type="button"
										disabled={
											zigna_aiStore.filteredDRGSelected.billingCodeType == '' ||
											zigna_aiStore.filteredDRGSelected.billingCodeType == undefined ||
											zigna_aiStore.filteredDRGSelected.billingCodeType == null ||
											!zigna_aiStore.filteredDRGSelected.billingCodeType?.length
										}
										className="NPI-submit btn btn-primary btn-sm"
										onClick={()=>{
											zigna_aiStore.resetFilteredDrgList()
											zigna_aiStore.setfdrgFilterData('billingCodes', null)											
																					
											zigna_aiStore.getfdrgBillingCodes()
										}}
									>
										<FontAwesomeIcon
											icon={faArrowRight}
											className="dropdown-arrow"
											size="lg"
										/>
									</button>
								</div>
							</div>
						</Col>
						<Col className=" col-auto col-auto">
							<div className="mb-3">
								<label className="form-label">Codes <span className='text-danger'>*</span></label>
								<div className="d-flex align-items-center h45 NPI-select">
									<Select
										className={`select-menu`}
										value={zigna_aiStore.filteredDRGSelected.billingCodes}
										onChange={(e, data) => {
											
												zigna_aiStore.setfdrgFilterData('billingCodes', e)
											
											zigna_aiStore.resetFilteredDrgList()
										}}
										isMulti
										getOptionLabel={(option) => `${option.name}`}
										styles={customStyles}
										options={
											zigna_aiStore.filteredDRGtemp.billingCodes?.length
												? zigna_aiStore.filteredDRGtemp.billingCodes 
												: []
										}
										allowSelectAll={true}
										
										closeMenuOnSelect={false}
										hideSelectedOptions={false}
										
									/>
								</div>
							</div>
						</Col>
						<Col className=" col-auto">
							<label className="form-label">{'   '}</label>
							<div className="d-flex align-item-center">
								<Button
									variant="primary"
									size="sm"
									className="submitFilter"
									onClick={(e) => {
										zigna_aiStore.getFilteredDrgList()
									}}
									type="button"
								>
									Submit
								</Button>
							</div>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		)
	})
)