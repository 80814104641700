import React, { useEffect, useState } from 'react'
import { Col, Row, Card, Button, Form, Badge } from '@themesberg/react-bootstrap'
import { inject, observer } from 'mobx-react'
import Select from '../../ui/Dropdown'

import { default as ReactSelect } from 'react-select'
import { components } from 'react-select'
import makeAnimated from 'react-select/animated'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useRef } from 'react'
import { useLocation } from 'react-router-dom';
import { getPermissions } from '../../../PermisionObject'
import * as XLSX from 'xlsx';


export default inject('zigna_aiStore',"authStore")(
	observer(({ zigna_aiStore, procCategory, hideModifier ,location,authStore}) => {
		
		const [billingCodes,setBillingCodes]=useState([])
		const [haveChanges,setHaveChanges]=useState(false)
		const [showEdit,setShowEdit]=useState(false)
		const permissions=getPermissions(authStore?.currentUser?.client_name)
		useEffect(() => {
			setBillingCodes([])
			setHaveChanges(false)
			setShowEdit(false)
			zigna_aiStore.setFilterData((zigna_aiStore.userType == 'facility'?'hospital':'ancillary'), null)
			// zigna_aiStore.setFilterData('ancillary', null)
			zigna_aiStore.setFilterData('npi', [])
			zigna_aiStore.setFilterData('health_system', [])
			
			zigna_aiStore.setFilterData('level1Desc', null)
			zigna_aiStore.setFilterData('procedureName', null)
			zigna_aiStore.setFilterData('modifier', null)
			zigna_aiStore.setFilterData('billingCodeType', null)
			
			zigna_aiStore.proceduresCatList = procCategory.map(category => ({
				id: category,
				name: category,
				value: category
			}))
			zigna_aiStore.setFilterData(
				'procedureCat',
				procCategory[0]
			)
			
		}, [zigna_aiStore.userType, procCategory,location])

		useEffect(()=>{
			if(zigna_aiStore.selectedFilterData.procedureName?.extra)
			setBillingCodes(zigna_aiStore.selectedFilterData.procedureName?.extra?.split(','))
			
		},[zigna_aiStore.selectedFilterData.procedureName])


		const handleChangeBillingCode=(code)=>{
			if(!haveChanges)
			return
			setHaveChanges(true)
			if(billingCodes.find(i=>i==code)){
				setBillingCodes(billingCodes.filter(i=>i!==code))
			
			}
			else{
				setBillingCodes([...billingCodes,code])
			}
		}

		const getLabelValue = (label)=>{
			let value='';
			switch(label){
				case 'Primary':
					value='MSDRG-APRDRG Crosswalk';
					break;
				case 'Secondary':
						value='Additional 1:1 mapping between APRDRG and MSDRG';
					break;	
				case 'Teritary':
						value=`Many to 1 mapping between APRDRG and MSDRG`;
					break;
				case 'Unmapped':
						value=`Codes that don't have a crosswalk between MSDRG and APRDRG`;
					break;
			}	
			return value;
		}

		const OptionForCodeType = (props) => {
			return props.data.id == '0' ? (
				<div>
					<components.Option {...props}>
						<input
							type="checkbox"
							checked={props.data.value != 'All' ? true : false}
							onChange={() => null}
						/>{' '}
						<label>{props.label} </label>
						
					</components.Option>
				</div>
			) : (
				<div>
					<components.Option {...props}>
						<input
							type="checkbox"
							checked={props.isSelected}
							onChange={() => null}
						/>{' '}
						<label>{props.label}</label>	
						<div><small >{getLabelValue(props.label)}</small></div>					
					</components.Option>
				</div>
			)
		}
		
		const downloadExcel = (data) => {
			// console.log(JSON.stringify(data));
			const FormatedData=data.map((e)=>{
				return {"reporting entity name":e.payerName,"negotiated type":e.typeName,price:e.price}
			});
			const worksheet = XLSX.utils.json_to_sheet(FormatedData);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
			//let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
			//XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
			XLSX.writeFile(workbook, "DataSheet.xlsx");
		  };

		return (
			<Card border="light" className="shadow-sm filterSection">
				<Card.Body>
					<h5>
						Filter{' '}
						<span className="downloadBtn ">
							<svg
								width="20"
								height="20"
								viewBox="0 0 123 121"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_4_39)">
									<path
										d="M84.58 47C85.2961 46.2624 86.1525 45.6755 87.0988 45.2737C88.0451 44.872 89.0621 44.6635 90.0902 44.6607C91.1182 44.6578 92.1364 44.8605 93.0849 45.257C94.0335 45.6534 94.8931 46.2355 95.6133 46.9691C96.3336 47.7026 96.8998 48.5728 97.2788 49.5285C97.6578 50.4841 97.8418 51.5059 97.82 52.5337C97.7983 53.5615 97.5712 54.5746 97.1522 55.5133C96.7332 56.4521 96.1306 57.2975 95.38 58L66.09 86.88C64.6464 88.2981 62.7036 89.0927 60.68 89.0927C58.6564 89.0927 56.7136 88.2981 55.27 86.88L26.4 58.37C24.9413 56.9365 24.1118 54.9823 24.094 52.9372C24.0762 50.8921 24.8715 48.9237 26.305 47.465C27.7385 46.0063 29.6927 45.1768 31.7378 45.159C33.7829 45.1412 35.7513 45.9365 37.21 47.37L53.1 63.12L53.26 7.65C53.3468 5.65118 54.2063 3.76414 55.6572 2.38657C57.1081 1.009 59.0371 0.248502 61.0378 0.265358C63.0384 0.282213 64.9544 1.0751 66.3818 2.47692C67.8093 3.87874 68.6369 5.78 68.69 7.78L68.54 62.78L84.58 47ZM0 113.48L0.1 83.3C0.0733066 82.2639 0.255513 81.233 0.635757 80.2689C1.016 79.3047 1.58649 78.427 2.31323 77.688C3.03996 76.9491 3.90804 76.364 4.86573 75.9677C5.82341 75.5715 6.85109 75.3721 7.88748 75.3815C8.92387 75.3909 9.94776 75.6089 10.8981 76.0225C11.8484 76.4361 12.7058 77.0368 13.419 77.7888C14.1322 78.5408 14.6866 79.4288 15.0493 80.3997C15.412 81.3706 15.5755 82.4046 15.53 83.44L15.46 105.44C46.1267 105.5 76.7633 105.5 107.37 105.44L107.44 83.32C107.459 81.2725 108.29 79.3163 109.751 77.8816C111.212 76.447 113.183 75.6514 115.23 75.67C117.277 75.6886 119.234 76.5197 120.668 77.9806C122.103 79.4415 122.899 81.4125 122.88 83.46L122.78 113.46H122.69C122.6 115.432 121.758 117.294 120.337 118.663C118.916 120.032 117.023 120.804 115.05 120.82C79.23 120.887 43.4367 120.887 7.67 120.82C5.69389 120.814 3.79555 120.049 2.36785 118.683C0.940148 117.317 0.0923821 115.454 0 113.48H0Z"
										fill="#B54491"
									/>
								</g>
								<defs>
									<clipPath id="clip0_4_39">
										<rect width="122.88" height="120.89" fill="white" />
									</clipPath>
								</defs>
							</svg>
						
							<span className="ml-5" onClick={()=>downloadExcel(zigna_aiStore.priceDiffAvgList?.data)}>
								Download
							</span>
							
						</span>
					</h5>
					<Row>
						<Col className=" col-auto col-auto">
							<div
								className="mb-3"
								title={zigna_aiStore.selectedFilterData[(zigna_aiStore.userType == 'facility'?'hospital':'ancillary')]?.name}
							>
								<label className="form-label">Health System <span className='text-danger'>*</span></label>
								<Select
									
									value={zigna_aiStore.selectedFilterData[(zigna_aiStore.userType == 'facility'?'hospital':'ancillary')]}
									options={zigna_aiStore[(zigna_aiStore.userType == 'facility'?'hospitalList':'ancillaryList')]}
									onChange={(e) => {
										zigna_aiStore.resetPayerPriceCompList()
										zigna_aiStore.setFilterData((zigna_aiStore.userType == 'facility'?'hospital':'ancillary'), e)
										setShowEdit(false)
										zigna_aiStore.setFilterData('npi', [])
										zigna_aiStore.setFilterData('health_system', [])
										setHaveChanges(false)
										zigna_aiStore.setFilterData('level1Desc', null)
										zigna_aiStore.setFilterData('procedureName', null)
										zigna_aiStore.setFilterData(
											'procedureCat',
											procCategory[0]
										)
										zigna_aiStore.setFilterData('modifier', null)

										zigna_aiStore.getNpiList()
										zigna_aiStore.getHospitalNameList()
										if(procCategory[0]==='DRG'){
											zigna_aiStore.getBillingCodeTypeList();
											}else{
											zigna_aiStore.getLevel1DescList()
											}
									}}
									getOptionLabel={(option) => `${option.name}`}
									
								/>
							</div>
						</Col>
						<Col className=" col-auto col-auto">
							<div
								className="mb-3"
							>
								{/* <label className="form-label">{zigna_aiStore.userType == 'facility'?'Hospital Name':'Ancillary'}</label> */}
								<label className="form-label">Hospital Name</label>
								<div className="d-flex align-items-center h45 NPI-select">
								<Select
                                    isMulti
									value={zigna_aiStore.selectedFilterData.health_system}
									options={zigna_aiStore.hospitalNameList.length
										? zigna_aiStore.hospitalNameList
										: []}
									onChange={(e) => {
										zigna_aiStore.setFilterData('health_system', e)
										zigna_aiStore.resetPayerPriceCompList()

										setShowEdit(false)
										setHaveChanges(false)
									}}
									getOptionLabel={(option) => `${option.name}`}
									allowSelectAll={true}
										
										closeMenuOnSelect={false}
										hideSelectedOptions={false}
									
								/>
								<button
										type="button"
										disabled={
											zigna_aiStore.selectedFilterData.health_system == '' ||
											zigna_aiStore.selectedFilterData.health_system == undefined ||
											zigna_aiStore.selectedFilterData.health_system == null ||
											!zigna_aiStore.selectedFilterData.health_system?.length
										}
										className="NPI-submit btn btn-primary btn-sm"
										onClick={()=>{
											// console.log(npiSelectRef)
											zigna_aiStore.resetPayerPriceCompList()
											zigna_aiStore.setFilterData('level1Desc', null)
											zigna_aiStore.setFilterData('procedureName', null)
											zigna_aiStore.setFilterData('modifier', null)
											zigna_aiStore.setFilterData('billingCodeType', null)
											zigna_aiStore.getNpiList()
											// if(procCategory[0]==='DRG'){
											// zigna_aiStore.getBillingCodeTypeList();
											// }else{
											// zigna_aiStore.getLevel1DescList()
											// }
										}}
									>
										<FontAwesomeIcon
											icon={faArrowRight}
											className="dropdown-arrow"
											size="lg"
										/>
									</button>
									</div>
							</div>
						</Col>
						<Col className=" col-auto col-auto">
							<div className="mb-3">
								<label className="form-label">NPIs
								{permissions?.showDemoButton?	<small style={{
		
			
			fontStyle: 'italic'
		}}>&nbsp;(Select all NPIs to get all payers)</small>:""}
								
								</label>
								<div className="d-flex align-items-center h45 NPI-select">
									<Select
										className={`select-menu`}
										value={zigna_aiStore.selectedFilterData.npi}
										onChange={(e, data) => {
											
												zigna_aiStore.setFilterData('npi', e)
											
											zigna_aiStore.resetPayerPriceCompList()
											setShowEdit(false)
											setHaveChanges(false)
										}}
										
										isMulti
										getOptionLabel={(option) => `${option.name}`}
										
										options={
											zigna_aiStore.npiList.length
												? zigna_aiStore.npiList
												: []
										}
										allowSelectAll={true}
										
										closeMenuOnSelect={false}
										hideSelectedOptions={false}
									
									/>
									<button
										type="button"
										disabled={
											zigna_aiStore.selectedFilterData.npi == '' ||
											zigna_aiStore.selectedFilterData.npi == undefined ||
											zigna_aiStore.selectedFilterData.npi == null ||
											!zigna_aiStore.selectedFilterData.npi?.length
										}
										className="NPI-submit btn btn-primary btn-sm"
										onClick={()=>{
											// console.log(npiSelectRef)
											zigna_aiStore.resetPayerPriceCompList()
											zigna_aiStore.setFilterData('level1Desc', null)
											zigna_aiStore.setFilterData('procedureName', null)
											zigna_aiStore.setFilterData('modifier', null)
											zigna_aiStore.setFilterData('billingCodeType', null)
										
											if(procCategory[0]==='DRG'){
											zigna_aiStore.getBillingCodeTypeList();
											}else{
											zigna_aiStore.getLevel1DescList()
											}
										}}
									>
										<FontAwesomeIcon
											icon={faArrowRight}
											className="dropdown-arrow"
											size="lg"
										/>
									</button>

								
								</div>
							
							</div>
						</Col>
						<Col className=" col-auto col-auto">
							<div className="mb-3 w220">
								<label className="form-label">Payers Count</label>
								<div className="d-flex gap-3 align-items-center h45">
									<Form.Range
										min="0"
										max="12"
										value={zigna_aiStore.selectedFilterData.payersCount}
										onChange={(e, data) => {
										
											zigna_aiStore.selectedFilterData.payersCount =
												e.target.value
												zigna_aiStore.setFilterData('billingCodeType', null)
											zigna_aiStore.setFilterData('level1Desc', null)
											zigna_aiStore.setFilterData('procedureName', null)
											zigna_aiStore.setFilterData('modifier', null)
										
										}}
										onMouseUp={(e, data) => {
											//zigna_aiStore.getProceduresCatList()
											zigna_aiStore.getBillingCodeTypeList()
											// zigna_aiStore.getLevel1DescList()
											zigna_aiStore.getLevel1DescList()
                      zigna_aiStore.resetPayerPriceCompList()
										}}
									/>
									<span className="range-slider__value">
										{zigna_aiStore.selectedFilterData.payersCount}
									</span>
								</div>
							</div>
						</Col>
						{zigna_aiStore.proceduresCatList.length!==1 &&
						<Col className=" col-auto">
							<div className="mb-3">
								<label className="form-label">Procedures Category<span className='text-danger'>*</span></label>
								<div
									className={`btn-group d-block procedureCategory ${
										zigna_aiStore.selectedFilterData.procedureCat != ''
											? ''
											: 'invalidFilter'
									}`}
									role="group"
									aria-label="Basic radio toggle button group"
								>
									{zigna_aiStore.proceduresCatList.length ? (
										zigna_aiStore.proceduresCatList.map((item) => {
											return (
												<div
													key={`filterdata_${item.name}`}
													onClick={(e) => {
														zigna_aiStore.resetPayerPriceCompList()
														zigna_aiStore.setFilterData(
															'procedureCat',
															item.value
														)
														zigna_aiStore.setFilterData('level1Desc', null)
														zigna_aiStore.setFilterData('procedureName', null)
														zigna_aiStore.setFilterData('modifier', null)
														zigna_aiStore.getLevel1DescList()
													}}
													className={`btn btn-outline-primary ${
														zigna_aiStore.selectedFilterData.procedureCat ==
														item.value
															? 'active'
															: ''
													}`}
												>
													<label className="" htmlFor="btnradio1">
														{item.name}
													</label>
												</div>
											)
										})
									) : (
										<span className="nodataFilter">No Data</span>
									)}
								</div>
								{/* {zigna_aiStore.selectedFilterData.procedureCat == '' && (
									<div className="invalid-input">
										Please select Procedure Category
									</div>
								)} */}
							</div>
						</Col> 
						}
						
						{procCategory[0]==='DRG' &&
						<Col className=" col-auto col-auto">
							<div className="mb-3">
							<label className="form-label">Code Type <span className='text-danger'>*</span></label>
								<div className="d-flex align-items-center h45 NPI-select">
									<Select
										className={`select-menu`}
										value={zigna_aiStore.selectedFilterData.billingCodeType}
										onChange={(e, data) => {
											
												zigna_aiStore.setFilterData('billingCodeType', e)
												setShowEdit(false)
												setHaveChanges(false)
											
										}}
										isMulti
										getOptionLabel={(option) => `${option.name}`}
									
										options={
											zigna_aiStore.billingCodeTypeList?.length
												? zigna_aiStore.billingCodeTypeList 
												: []
										}
										allowSelectAll={true}
										components={{
											Option:OptionForCodeType,
										}}
										closeMenuOnSelect={false}
										hideSelectedOptions={false}
										
									/>
									<button
										type="button"
										disabled={
											zigna_aiStore.selectedFilterData.billingCodeType == '' ||
											zigna_aiStore.selectedFilterData.billingCodeType == undefined ||
											zigna_aiStore.selectedFilterData.billingCodeType == null ||
											!zigna_aiStore.selectedFilterData.billingCodeType?.length
										}
										className="NPI-submit btn btn-primary btn-sm"
										onClick={()=>{
											zigna_aiStore.setFilterData('level1Desc', null)
											zigna_aiStore.setFilterData('procedureName', null)
											zigna_aiStore.setFilterData('modifier', null)
											zigna_aiStore.getLevel1DescList()
										}}
									>
										<FontAwesomeIcon
											icon={faArrowRight}
											className="dropdown-arrow"
											size="lg"
										/>
									</button>
								</div>
							</div>
						</Col>
						}
						<Col className=" col-auto">
							<div className="mb-3">
								<label className="form-label">Level 1 Description<span className='text-danger'>*</span></label>
								<Select
									className={`select-menu ${
										zigna_aiStore.selectedFilterData.level1Desc != ''
											? ''
											: 'invalidFilter'
									}`}
									aria-label="Default select example"
									value={zigna_aiStore.selectedFilterData.level1Desc}
									isMulti={false}
									onChange={(e) => {
										zigna_aiStore.resetPayerPriceCompList()
										zigna_aiStore.setFilterData('level1Desc', e)
										setShowEdit(false)
										zigna_aiStore.setFilterData('procedureName', null)
										zigna_aiStore.setFilterData('modifier', null)
										//zigna_aiStore.getmodifierList()
										zigna_aiStore.getProcedureNameList()
										setHaveChanges(false)
									}}
									getOptionLabel={(option) => `${option.name}`}
								
									options={zigna_aiStore.level1DescList}
								/>
								{/* {zigna_aiStore.selectedFilterData.level1Desc == '' && (
									<div className="invalid-input">
										Please select Level 1 Description
									</div>
								)} */}
							</div>
						</Col>
						<Col className=" col-auto">
							<div className="mb-3 filterProcedureName">
								<label className="form-label">Procedure Name <span className='text-danger'>*</span></label>
								<Select
									className={`select-menu`}
									aria-label="Default select example"
									value={zigna_aiStore.selectedFilterData.procedureName}
									onChange={(e) => {
										zigna_aiStore.resetPayerPriceCompList()
										zigna_aiStore.setFilterData('procedureName', e)
										setShowEdit(false)
										zigna_aiStore.setFilterData('modifier', null)
										setHaveChanges(false)
										zigna_aiStore.getmodifierList()
									}}
									getOptionLabel={(option) => `${option.name}`}
								
									options={zigna_aiStore.procedureNameList}
								/>
								{/* {zigna_aiStore.selectedFilterData.procedureName == '' && (
									<div className="invalid-input">Please select Procedure</div>
								)} */}
							</div>
						</Col>
						{ !hideModifier ? (<Col className=" col-auto">
							<div className="mb-3">
								<label className="form-label">Modifiers</label>
								<Select
									className={`select-menu`}
									aria-label="Default select example"
									value={zigna_aiStore.selectedFilterData.modifier}
									onChange={(e) => {
										zigna_aiStore.resetPayerPriceCompList()
										zigna_aiStore.setFilterData('modifier', e)
										setShowEdit(false)
										setHaveChanges(false)
									}}
									getOptionLabel={(option) => `${option.name}`}
									
									options={zigna_aiStore.modifierList}
								/>
								{/* {zigna_aiStore.selectedFilterData.procedureName == '' && (
									<div className="invalid-input">Please select Procedure</div>
								)} */}
							</div>
						</Col>) : null}
						{/* <div className="col-auto sep_filter">
							<span className="sepText"></span>
						</div> */}
						<Col className=" col-auto">
							<div className="mb-3">
								<label className="form-label">Aggregation Measure<span className='text-danger'>*</span></label>
								<Select
									className={`select-menu ${
										zigna_aiStore.selectedFilterData.aggregationMeasure != ''
											? ''
											: 'invalidFilter'
									}`}
									
									value={zigna_aiStore.selectedFilterData.aggregationMeasure}
									isMulti={false}
									onChange={(e) => {
										setShowEdit(false)
										zigna_aiStore.resetPayerPriceCompList()
										zigna_aiStore.setFilterData('aggregationMeasure', e)
										setHaveChanges(false)
									}}
									getOptionLabel={(option) => `${option.name}`}
								
									options={zigna_aiStore.aggregationMeasureList}
								/>
								{/* {zigna_aiStore.selectedFilterData.procedureName == '' && (
									<div className="invalid-input">Please select Procedure</div>
								)} */}
							</div>
						</Col>
						<Col className=" col-auto">
							<label className="form-label">{'   '}</label>
							<div className="d-flex align-item-center">
								<Button
									variant="primary"
									size="sm"
									className="submitFilter"
									disabled={haveChanges}
									onClick={(e) => {
										zigna_aiStore.getPayerPriceCompList()
										setShowEdit(true)
									}}
									type="button"
								>
									Submit
								</Button>
							</div>
						</Col>
					</Row>
					<div className='px-2 d-flex'>

					{(zigna_aiStore.selectedFilterData.procedureName?.extra?.split(',') ||[])?.length?	<div  style={{ display: 'flex', flexDirection: 'row' ,border:"1px solid #f0f0f0",width:"auto",paddingTop:8,overflowX:"scroll",height:60}}>
							{(zigna_aiStore.selectedFilterData.procedureName?.extra?.split(',') ||[]).map((elem,i) => {
								let exists=billingCodes.find(i=>i==elem)
								return (
									<h5 key={i} style={{ marginRight:8 }}><Badge style={{background:exists?"rgb(61, 34, 96)":"grey"}}  onClick={()=>handleChangeBillingCode(elem)} className='p-2'>{elem}</Badge> </h5>
								)
							})}
						
						</div>:""}
						{haveChanges&&billingCodes.length&&showEdit?
							<Button 
							variant='outline-primary' 
							size="sm" 
							className='mt-2'
							onClick={()=>{zigna_aiStore.getPayerPriceCompList(billingCodes.length?billingCodes.map(i=>i.trim()):"");setHaveChanges(false)}}
							style={{height:32,marginLeft:12}}>Save</Button>:billingCodes?.length&&showEdit?<Button 
							variant='outline-primary' 
							size="sm" 
							className='mt-2'
							onClick={()=>{setHaveChanges(true)}}
							style={{height:32,marginLeft:12}}>Edit</Button>:""}
					</div>
				</Card.Body>
			</Card>
		)
	})
)
